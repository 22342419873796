import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
// import timeSlotSlice from "../redux/timeSlotSlice/timeSlot";
import insuranceCareerListSlice from '../redux/insuranceSlice/insuranceCareerSlice';
import insurancePlanSlice from '../redux/insuranceSlice/insurancePlanSlice';
import locationListSlice from '../redux/locationSlice/locationSlice';
import timeSlotSlice from '../redux/timeSlotSlice/timeSlot';
import appointmentSlice from '../redux/AppointmentSlice/AppointmentSlice';
import ClintSlice from '../redux/clinicsSlice/ClinicsSlice';
import OrganizationsSlice from '../redux/OrganizationSlice/OrganizationSlice';
import AuthSlice from '../redux/authSlice/AuthSlice';
import EditUsersSlice from '../redux/getUsers/EditUsersSlice';
import usersSlice from '../redux/getUsers/getUsersSlice';
import operatoriesSlice from '../redux/operatoriesSlice/OperatoriesSlice';
import AddOrEditClinicSlice from '../redux/clinicsSlice/AddOrEditClinicSlice';
import AddOrEditOrgSlice from '../redux/OrganizationSlice/AddOrEditOrgSlice';
import GetRolesSlice from '../redux/roleSlices/GetRolesSlice';
import EditOrDeleteSlice from '../redux/roleSlices/EditOrDeleteSlice';
import AvailabilitySlice from '../redux/availability/AvailabilitySlice';
import PatientsSlice from '../redux/patientsSlice/PatientsSlice';
import dashBoardSlice from '../redux/DashboardSice/dashBoardSlice';
import PracticeSlice from '../redux/PracticeSlice/PracticeSlice';
import SchedulerSlice from '../redux/schedularSlice/schedularSlice';
import AppointmentBookingSlice from '../redux/AppointmentSlice/AppointmentFormSlice';
import EditAppointmentSlice from '../redux/AppointmentSlice/AppointmentEditSlice';
import paymentsSlice from '../redux/paymentsSlice/paymentsSlice';
import userAuditSlice from '../redux/getUsers/userAuditSlice';
import smsSlice from '../redux/smsSlice/smsSlice';
import PracticeIQSlice from '../redux/PracticeIQSlice/PracticeIQSlice';
import practiceIQDashboardSlice from '../redux/PracticeIQSlice/DashboardDataSlice';
import PracticeIQGridSlice from '../redux/PracticeIQSlice/PracticeIQGridSlice';
import practiceIQFinancial from '../redux/PracticeIQSlice/FinancialSlice';
import FollowUpCampaignsSlice from '../redux/RevenueIQSlice/FollowUpCampaignsSlice';
import SettingSlice from '../redux/settingApiSlice/SettingSlice';
import facebookSlice from '../redux/reviewSlice/facebookSlice';
import insurancePageSlice from '../redux/othersSlice/insurancePageSlice';
import CustomDashSlice from '../redux/CustomDashboardSlice/CustomDashSlice';
import CustomDashDataSlice from '../redux/CustomDashboardSlice/CustomDashDataSlice';
import KpiSettingSlice from '../redux/settingApiSlice/KpiSettingSlice';
import PatientFinderSlice from '../redux/RevenueIQSlice/PatientFinderSlice';
import PatientCommunicationSlice from '../redux/PracticeIQSlice/PatientCommunicationSlice';
import FormsSlice from '../redux/FormsSlice/FormsSlice';
import DocumentSlice from '../redux/clinicsSlice/DocumentSlice';
import AdminReportSlice from '../redux/AdminReportSlice/AdminReportSlice';
import GHLSlice from '../redux/GHLSlice/GHLSlice';
import doctorSlice from '../redux/DoctorSlice/doctorSlice';
import CollectionSlice from '../redux/CollectionSlice/CollectionSlice';

import DoctorDetailsSlice from '../redux/DoctorDetailsSlice/DoctorDetailsSlice';
import DashboardProviderSlice from '../redux/DashboardProvider/DashboardProviderSlice';
import ProductionLeaderBoardSlice from '../redux/ProductiomLeaderBoardSlice.js/ProductionLeaderBoardSlice';
import GrossChartSlice from '../redux/GrossChartSlice/GrossChartSlice';
import ExceptionSlice from '../redux/ExceptionSlice/ExceptionSlice';
import BookingReportSlice from '../redux/BookingReportSlice.js/BookingReportSlice';
import EmployeeProdValueSlice from '../redux/employeeprodvalueSlice/EmployeeProdValueSlice';
import OtpSendSlice from '../redux/OtpSendSlice/OtpSendSlice';
import getEmployeeSlice from '../redux/getEmployeeSlice/getEmployeeSlice';
import clinicsSlice from '../redux/clinicsSlice/ClinicsSlice';
import TreatMentCoOrdinatorSlice from '../redux/TreateMentCoOrdinatorSlice/TreatMentCoOrdinatorSlice';
import monthsDataSlice from '../redux/monthDateSlice/MonthDateSlice';
import enterPriseReportSlice from '../redux/enterPriseslice/EnterPriseSlice';
import doctorDashboardtSlice from '../redux/DoctorDashboardSlice/DoctorDashboardSlice';
import ExceptionFESlice from '../redux/exceptionFESlice/ErrorBoundarySlice';
import blockDaySlice from '../redux/BlockDaySlice/BlockDaySlice';
import CollectionPaymentReportSlice from '../redux/CollectionPaymentSlice/CollectionPaymentSlice';
import otpSlice from '../redux/OtpSlice/OtpSlice'
// import opdSlice from '../redux/getOpdSlice/getOpdSlice';
const reducers = combineReducers({
  // timeSlot: timeSlotSlice,
  insuranceCareerList: insuranceCareerListSlice,
  insurancePlanList: insurancePlanSlice,
  locationList: locationListSlice,
  timeSlot: timeSlotSlice,
  appointments: appointmentSlice,
  clinics: ClintSlice,
  organizations: OrganizationsSlice,
  auth: AuthSlice,
  usersList: usersSlice,
  editUsers: EditUsersSlice,
  operatoriesList: operatoriesSlice,
  addOrEditOrg: AddOrEditOrgSlice,
  AddOrEditClinic: AddOrEditClinicSlice,
  rolesList: GetRolesSlice,
  rolesAddEditDelete: EditOrDeleteSlice,
  availabilityList: AvailabilitySlice,
  patientsList: PatientsSlice,
  paymentsList: paymentsSlice,
  dashboard: dashBoardSlice,
  practice: PracticeSlice,
  schedular: SchedulerSlice,
  AppointmentBooking: AppointmentBookingSlice,
  editAppointSlice: EditAppointmentSlice,
  usersAuditList: userAuditSlice,
  patientDetailsList: PatientsSlice,
  smsList: smsSlice,
  practiceIQList: PracticeIQSlice,
  practiceIQData: practiceIQDashboardSlice,
  TxAccpGridList: PracticeIQGridSlice,
  PracticeFinancial: practiceIQFinancial,
  FollowUpCampaignsList: FollowUpCampaignsSlice,
  SettingSliceList: SettingSlice,
  FacebookSlice: facebookSlice,
  insurancePageSlice: insurancePageSlice,
  CustomDashboardSlice: CustomDashSlice,
  CustomDashboardKPISlice: CustomDashDataSlice,
  KPISettingSlice: KpiSettingSlice,
  PatientFinderData: PatientFinderSlice,
  patientCommunicationData: PatientCommunicationSlice,
  FormsSlice: FormsSlice,
  DocumentSlice: DocumentSlice,
  AdminReport: AdminReportSlice,
  GHLPatientData: GHLSlice,
  doctorSlice: doctorSlice,
  collection: CollectionSlice,
  doctorDetails: DoctorDetailsSlice,
  dashboardProvider: DashboardProviderSlice,
  productionLeaderBoardSlices: ProductionLeaderBoardSlice,
  GrossChartSlices: GrossChartSlice,
  ExceptionSlices: ExceptionSlice,
  bookigReportSlices: BookingReportSlice,
  otpSlices: OtpSendSlice,
  // opdSlices: opdSlice,
  getEmployeeSlices: getEmployeeSlice,
  clinicsSlices: clinicsSlice,
  TreatMentCoOrdinatorSlices: TreatMentCoOrdinatorSlice,
  monthsDataSlices: monthsDataSlice,
  EmployeeProdValueSlices: EmployeeProdValueSlice,
  enterPriseReportSlices: enterPriseReportSlice,
  doctorDashboardtSlices: doctorDashboardtSlice,
  exceptionFE: ExceptionFESlice,
  blockDaySlices: blockDaySlice,
  CollectionPaymentReportSlices: CollectionPaymentReportSlice,
  OtpSlices:otpSlice
  
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;
