import { useEffect, useState } from 'react';
import './App.scss';
import 'antd/dist/antd.min.css';
import AppointmentContextProvider from './context/AppointmentBook/AppointmentBook';
import Navigation from './route/route';
import { defaultLogOut, getUser } from './services';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from 'react-bootstrap';
import ErrorFound from './pages/notfound/ErrorFound';

function App() {
  const handleError = (error, errorInfo) => {};
  const token = localStorage.getItem('_t');

  useEffect(() => {
    const user = getUser();

    // const timestamp = user?.exp;
    // const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds
    // console.log(date.toLocaleString());

    const checkExpTimeAndLogOut = () => {
      if (user?.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime >= user.exp) {
          defaultLogOut();
        }
      } else {
        defaultLogOut();
      }
    };

    checkExpTimeAndLogOut();
  }, [token]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFound} onError={handleError}>
      <AppointmentContextProvider>
        {/* <AppointmentBook /> */}

        <Navigation />
      </AppointmentContextProvider>
      {/* <BuggyComponent /> */}
    </ErrorBoundary>
  );
}

export default App;
