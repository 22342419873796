import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  monthsData: [],
  isLoadingMonthsData: false,
  isError: null,
  status: '',
};

// step 1 -> Change method  HTTP GET to POST

// step 2  -> Add a body to his

// { "OperatoryIds": [], "ProviderIds": [], "Status": [], "Services": [], "StartTime": "", "EndTime": "" } this is first time load

// step 3  -> select any values in the filter sent this type of body

// { "OperatoryIds": [3, 4], "ProviderIds": [3, 4], "Status": ["Scheduled", "Broken"], "Services": ["D7140", "D6010", "D0140"], "StartTime": "09:00", "EndTime": "18:00" }

export const fetchMonthsData = createAsyncThunk(
  'monthsData/monthsDataList',
  async (params, { rejectWithValue }) => {
    try {
      // console.log('months--->params', params);

      const { data } = await axiosInstance.post(
        `api/Appointment/get-ApptSchedulerByMonths?ClinicId=${params.inputFields}&startdate=${params.currentDate}&IsFilter=${params.IsFilter}`,
        params.values
      );

      // console.log('API R/esponse:', data);
      return data;
    } catch (error) {
      console.error('Error fetching months data:', error);
      return rejectWithValue('Data Not Found');
    }
  }
);

const monthsDataSlice = createSlice({
  name: 'monthsDataList',
  initialState,
  extraReducers: {
    [fetchMonthsData.fulfilled]: (state, { payload }) => {
      // console.log("months->payload",payload);
      if (payload.status.isSuccess === true) {
        state.monthsData = payload.data;
      } else {
        state.monthsData = [];
      }
      state.isLoadingMonthsData = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchMonthsData.pending]: (state, { payload }) => {
      state.isLoadingMonthsData = true;
      state.status = '';
      state.isError = null;
      state.monthsData = [];
    },
    [fetchMonthsData.rejected]: (state, { payload }) => {
      state.isLoadingMonthsData = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.monthsData = [];
    },
  },
});

export default monthsDataSlice.reducer;
