import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';
import { defaultOtpVerify, getUser } from '../../services';

const initialState = {
  userName: null,
  authData: null,
  isLoadingOtpData: false,
  isError: null,
  isLogin: false,
  status: '',
};

// {baseurl}/api/Auth/verify-2fa

// export const submitOtp = createAsyncThunk(
//   'otp/otpVerify',
//   async (values, { rejectWithValue }) => {
//     try {
//       console.log('-->values', values);

// const { data } = await axiosInstance.post(`api/Auth/verify-2fa`, values);
// return data;
//     } catch (error) {
//       console.error('Error fetching months data:', error);
//       return rejectWithValue('Data Not Found');
//     }
//   }
// );

export const submitOtp = createAsyncThunk(
  'auth/otpVerify',
  async (values, { rejectWithValue }) => {
    try {
      const data = await defaultOtpVerify(values);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const otpSlice = createSlice({
  name: 'otpVerify',
  initialState,
  extraReducers: {
    [submitOtp.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.authData = payload?.pageUrl;
      state.userName = getUser();
      state.isLogin = true;
      state.isLoadingOtpData = false;
      state.status = 'Success';
      state.isError = null;
    },
    [submitOtp.pending]: (state, { payload }) => {
      state.isLoadingOtpData = true;
      state.status = '';
      state.isLogin = false;
      state.isError = null;
      state.OtpData = null;
    },
    [submitOtp.rejected]: (state, { payload }) => {
      state.isLoadingOtpData = false;
      state.isError = payload;
      state.isLogin = false;
      state.status = 'Rejected';
      state.OtpData = null;
    },
  },
});

export default otpSlice.reducer;
